<template>
  <div>
    <v-card>
      <v-toolbar dense dark color="primary">
        <v-toolbar-title><h4 class="font-weight-light">COMPANY RECEIVABLE (CASH ADVANCE)</h4>
        </v-toolbar-title>
      </v-toolbar>
      <v-layout row wrap class="align-center mx-2 mt-5">
        <v-flex xs12 md12
                v-if="position === 'ADMIN' || position === 'TREASURY' || position === 'ACCOUNTING' || position === 'CMD'|| position === 'FINANCE'|| position === 'AR/AP'">
          <v-form ref="form2" class="multi-col-validation">
            <v-select v-model="status"
                      class="mx-2"
                      dense
                      outlined
                      label="Status"
                      :items="['Unpaid','Paid']"
                      :rules="rules.combobox_rule"
                      @change="selected_status"
            ></v-select>
            <v-checkbox v-model="is_search" label="Search?"></v-checkbox>
            <v-autocomplete
              v-if="is_search"
              dense
              outlined
              v-model="search"
              :loading="isLoading"
              @keyup.enter="searching($event.target.value)"
              hide-no-data
              hide-selected
              item-text="name"
              item-value="id"
              label="Search"
              class="mx-2"
              placeholder="Press Enter to Search "
              :prepend-icon="icons.mdiAccountSearchOutline"
            ></v-autocomplete>
          </v-form>
        </v-flex>
        <v-layout col>
          <v-flex xs12 md6 class="mx-2">
            <v-text-field outlined v-model="total_amount" label="Total Amount"
                          readonly></v-text-field>
          </v-flex>
          <v-flex xs12 md6 class="mx-2">
            <v-text-field outlined v-model="total_balance" label="Total Balance"
                          readonly></v-text-field>
          </v-flex>
        </v-layout>
        <v-flex xs12 md12 class="mx-2">
          LEGENDS -
          (SOA-
          <v-icon
            class="mr-2"
            color="success"
          >
            {{icons.mdiPrinter}}
          </v-icon>
          )
          <div
            v-if="position === 'ADMIN' || position === 'TREASURY' || position === 'ACCOUNTING' || position === 'CMD'|| position === 'FINANCE'|| position === 'AR/AP'">
            (CHANGE CATEGORY-
            <v-icon
              class="mr-2"
              color="warning"
            >
              {{icons.mdiTransferRight}}
            </v-icon>
            ),
            (BOD CHARGES-
            <v-icon
              class="mr-2"
              color="warning"
            >
              {{icons.mdiFolderSwap}}
            </v-icon>
            )
          </div>
        </v-flex>
        <v-flex xs12 md12 class="mx-2">
          <v-tabs
            v-model="tab"
            show-arrows
          >
            <v-tab
              v-for="tab in position === 'ADMIN' || position === 'TREASURY' || position === 'ACCOUNTING' || position === 'CMD'|| position === 'FINANCE'|| position === 'AR/AP'?tabs:tabs1"
              :key="tab.title"
            >
              <v-icon
                size="20"
                class="me-3"
              >
                {{ tab.icon }}
              </v-icon>
              <span>{{ tab.title }}</span>
            </v-tab>
          </v-tabs>
          <v-tabs-items v-model="tab">
            <v-tab-item>
              <v-data-table dense
                            :headers="headers"
                            :items="data_items"
              >
                <template v-slot:item="{ item }">
                  <tr>
                    <td>
                      {{ item.id }}
                    </td>
                    <td>
                      {{ item.request_id }}
                    </td>
                    <td>
                      {{ item.category.category }}
                    </td>
                    <td>
                      {{
                      (item.cv!=null?item.cv.transaction_month.month_of:(item.ckv!=null?item.ckv.transaction_month.month_of:(item.ftv!=null?item.ftv.transaction_month.month_of:'')))}}
                    </td>
                    <td>
                      {{ item.branch!=null?item.branch.branch_code:'' }}
                    </td>
                    <td>
                      {{
                      (item.cv!=null?'CV#'+item.cv.voucher_no:(item.ckv!=null?'CKV#'+item.ckv.voucher_no:(item.ftv!=null?'FTV#'+item.ftv.voucher_no:'')))}}
                    </td>
                    <td>
                      {{ item.payment_for}}
                    </td>
                    <td>
                      {{ item.description }}
                    </td>
                    <td>
                      {{ formatPrice(item.amount) }}
                    </td>
                    <td>
                      {{ balance_calculation(item) }}
                    </td>
                    <td>
                      <v-icon
                        class="mr-2"
                        color="success"
                        @click="print_soa(item)"
                      >
                        {{icons.mdiPrinter}}
                      </v-icon>
                      <v-icon
                        v-if="position === 'ADMIN'"
                        class="mr-2"
                        color="warning"
                        @click="transfer_data(item)"
                      >
                        {{icons.mdiTransferRight}}
                      </v-icon>
                      <v-icon
                        v-if="position === 'ADMIN'&& (employee_id===1||employee_id===2)"
                        class="mr-2"
                        color="warning"
                        @click="bod_charge(item)"
                      >
                        {{icons.mdiFolderSwap}}
                      </v-icon>
                    </td>
                  </tr>
                </template>
              </v-data-table>
            </v-tab-item>
            <v-tab-item>
              <v-data-table dense
                            :headers="headers"
                            :items="data_items2"
              >
                <template v-slot:item="{ item }">
                  <tr>
                    <td>
                      {{ item.id }}
                    </td>
                    <td>
                      {{ item.request_id }}
                    </td>
                    <td>
                      {{ item.category.category }}
                    </td>
                    <td>
                      {{
                      (item.cv!=null?item.cv.transaction_month.month_of:(item.ckv!=null?item.ckv.transaction_month.month_of:(item.ftv!=null?item.ftv.transaction_month.month_of:'')))}}
                    </td>
                    <td>
                      {{ item.branch!=null?item.branch.branch_code:'' }}
                    </td>
                    <td>
                      {{
                      (item.cv!=null?'CV#'+item.cv.voucher_no:(item.ckv!=null?'CKV#'+item.ckv.voucher_no:(item.ftv!=null?'FTV#'+item.ftv.voucher_no:'')))}}
                    </td>
                    <td>
                      {{ item.payment_for}}
                    </td>
                    <td>
                      {{ item.description }}
                    </td>
                    <td>
                      {{ formatPrice(item.amount) }}
                    </td>
                    <td>
                      {{ balance_calculation(item) }}
                    </td>
                    <td>
                      <v-icon
                        class="mr-2"
                        color="success"
                        @click="print_soa(item)"
                      >
                        {{icons.mdiPrinter}}
                      </v-icon>
                      <v-icon
                        v-if="position === 'ADMIN'"
                        class="mr-2"
                        color="warning"
                        @click="transfer_data(item)"
                      >
                        {{icons.mdiTransferRight}}
                      </v-icon>
                      <v-icon
                        v-if="position === 'ADMIN'&& (employee_id===1||employee_id===2)"
                        class="mr-2"
                        color="warning"
                        @click="bod_charge(item)"
                      >
                        {{icons.mdiFolderSwap}}
                      </v-icon>
                    </td>
                  </tr>
                </template>
              </v-data-table>
            </v-tab-item>
            <v-tab-item>
              <v-data-table dense
                            :headers="headers"
                            :items="data_items3"
              >
                <template v-slot:item="{ item }">
                  <tr>
                    <td>
                      {{ item.id }}
                    </td>
                    <td>
                      {{ item.request_id }}
                    </td>
                    <td>
                      {{ item.category.category }}
                    </td>
                    <td>
                      {{
                      (item.cv!=null?item.cv.transaction_month.month_of:(item.ckv!=null?item.ckv.transaction_month.month_of:(item.ftv!=null?item.ftv.transaction_month.month_of:'')))}}
                    </td>
                    <td>
                      {{ item.branch!=null?item.branch.branch_code:'' }}
                    </td>
                    <td>
                      {{
                      (item.cv!=null?'CV#'+item.cv.voucher_no:(item.ckv!=null?'CKV#'+item.ckv.voucher_no:(item.ftv!=null?'FTV#'+item.ftv.voucher_no:'')))}}
                    </td>
                    <td>
                      {{ item.payment_for}}
                    </td>
                    <td>
                      {{ item.description }}
                    </td>
                    <td>
                      {{ formatPrice(item.amount) }}
                    </td>
                    <td>
                      {{ balance_calculation(item) }}
                    </td>
                    <td>
                      <v-icon
                        class="mr-2"
                        color="success"
                        @click="print_soa(item)"
                      >
                        {{icons.mdiPrinter}}
                      </v-icon>
                      <v-icon
                        v-if="position === 'ADMIN'"
                        class="mr-2"
                        color="warning"
                        @click="transfer_data(item)"
                      >
                        {{icons.mdiTransferRight}}
                      </v-icon>
                      <v-icon
                        v-if="position === 'ADMIN'&& (employee_id===1||employee_id===2)"
                        class="mr-2"
                        color="warning"
                        @click="bod_charge(item)"
                      >
                        {{icons.mdiFolderSwap}}
                      </v-icon>
                    </td>
                  </tr>
                </template>
              </v-data-table>
            </v-tab-item>
            <v-tab-item>
              <v-data-table dense
                            :headers="headers"
                            :items="data_items4"
              >
                <template v-slot:item="{ item }">
                  <tr>
                    <td>
                      {{ item.id }}
                    </td>
                    <td>
                      {{ item.request_id }}
                    </td>
                    <td>
                      {{ item.category.category }}
                    </td>
                    <td>
                      {{
                      (item.cv!=null?item.cv.transaction_month.month_of:(item.ckv!=null?item.ckv.transaction_month.month_of:(item.ftv!=null?item.ftv.transaction_month.month_of:'')))}}
                    </td>
                    <td>
                      {{ item.branch!=null?item.branch.branch_code:'' }}
                    </td>
                    <td>
                      {{
                      (item.cv!=null?'CV#'+item.cv.voucher_no:(item.ckv!=null?'CKV#'+item.ckv.voucher_no:(item.ftv!=null?'FTV#'+item.ftv.voucher_no:'')))}}
                    </td>
                    <td>
                      {{ item.payment_for}}
                    </td>
                    <td>
                      {{ item.description }}
                    </td>
                    <td>
                      {{ formatPrice(item.amount) }}
                    </td>
                    <td>
                      {{ balance_calculation(item) }}
                    </td>
                    <td>
                      <v-icon
                        class="mr-2"
                        color="success"
                        @click="print_soa(item)"
                      >
                        {{icons.mdiPrinter}}
                      </v-icon>
                      <v-icon
                        v-if="position === 'ADMIN'"
                        class="mr-2"
                        color="warning"
                        @click="transfer_data(item)"
                      >
                        {{icons.mdiTransferRight}}
                      </v-icon>
                      <v-icon
                        v-if="position === 'ADMIN'&& (employee_id===1||employee_id===2)"
                        class="mr-2"
                        color="warning"
                        @click="bod_charge(item)"
                      >
                        {{icons.mdiFolderSwap}}
                      </v-icon>
                    </td>
                  </tr>
                </template>
              </v-data-table>
            </v-tab-item>
            <v-tab-item>
              <v-data-table dense
                            :headers="headers"
                            :items="data_items5"
              >
                <template v-slot:item="{ item }">
                  <tr>
                    <td>
                      {{ item.id }}
                    </td>
                    <td>
                      {{ item.request_id }}
                    </td>
                    <td>
                      {{ item.category.category }}
                    </td>
                    <td>
                      {{
                      (item.cv!=null?item.cv.transaction_month.month_of:(item.ckv!=null?item.ckv.transaction_month.month_of:(item.ftv!=null?item.ftv.transaction_month.month_of:'')))}}
                    </td>
                    <td>
                      {{ item.branch!=null?item.branch.branch_code:'' }}
                    </td>
                    <td>
                      {{
                      (item.cv!=null?'CV#'+item.cv.voucher_no:(item.ckv!=null?'CKV#'+item.ckv.voucher_no:(item.ftv!=null?'FTV#'+item.ftv.voucher_no:'')))}}
                    </td>
                    <td>
                      {{ item.payment_for}}
                    </td>
                    <td>
                      {{ item.description }}
                    </td>
                    <td>
                      {{ formatPrice(item.amount) }}
                    </td>
                    <td>
                      {{ balance_calculation(item) }}
                    </td>
                    <td>
                      <v-icon
                        class="mr-2"
                        color="success"
                        @click="print_soa(item)"
                      >
                        {{icons.mdiPrinter}}
                      </v-icon>
                      <v-icon
                        v-if="position === 'ADMIN'"
                        class="mr-2"
                        color="warning"
                        @click="transfer_data(item)"
                      >
                        {{icons.mdiTransferRight}}
                      </v-icon>
                      <v-icon
                        v-if="position === 'ADMIN'&& (employee_id===1||employee_id===2)"
                        class="mr-2"
                        color="warning"
                        @click="bod_charge(item)"
                      >
                        {{icons.mdiFolderSwap}}
                      </v-icon>
                    </td>
                  </tr>
                </template>
              </v-data-table>
            </v-tab-item>
            <v-tab-item>
              <v-data-table dense
                            :headers="headers"
                            :items="data_items6"
              >
                <template v-slot:item="{ item }">
                  <tr>
                    <td>
                      {{ item.id }}
                    </td>
                    <td>
                      {{ item.request_id }}
                    </td>
                    <td>
                      {{ item.category.category }}
                    </td>
                    <td>
                      {{
                      (item.cv!=null?item.cv.transaction_month.month_of:(item.ckv!=null?item.ckv.transaction_month.month_of:(item.ftv!=null?item.ftv.transaction_month.month_of:'')))}}
                    </td>
                    <td>
                      {{ item.branch!=null?item.branch.branch_code:'' }}
                    </td>
                    <td>
                      {{
                      (item.cv!=null?'CV#'+item.cv.voucher_no:(item.ckv!=null?'CKV#'+item.ckv.voucher_no:(item.ftv!=null?'FTV#'+item.ftv.voucher_no:'')))}}
                    </td>
                    <td>
                      {{ item.payment_for}}
                    </td>
                    <td>
                      {{ item.description }}
                    </td>
                    <td>
                      {{ formatPrice(item.amount) }}
                    </td>
                    <td>
                      {{ balance_calculation_expense(item) }}
                    </td>
                    <td>
                      <v-icon
                        class="mr-2"
                        color="success"
                        @click="print_soa(item)"
                      >
                        {{icons.mdiPrinter}}
                      </v-icon>
                      <v-icon
                        v-if="position === 'ADMIN'"
                        class="mr-2"
                        color="warning"
                        @click="transfer_data(item)"
                      >
                        {{icons.mdiTransferRight}}
                      </v-icon>
                      <v-icon
                        v-if="position === 'ADMIN'&& (employee_id===1||employee_id===2)"
                        class="mr-2"
                        color="warning"
                        @click="bod_charge(item)"
                      >
                        {{icons.mdiFolderSwap}}
                      </v-icon>
                    </td>
                  </tr>
                </template>
              </v-data-table>
            </v-tab-item>
          </v-tabs-items>
        </v-flex>
      </v-layout>
    </v-card>
    <snack-bar-dialog
      :snackbar_flag="this.snackbar"
      :color="this.snackbar_color"
      :snackbar_text="this.snackbar_text"
    />
    <v-dialog v-model="Transfer" persistent max-width="50%">
      <v-card :key="this.key">
        <v-card-text>
          <v-form ref="form3" class="multi-col-validation">
            <v-toolbar dense dark color="primary" class="mt-5 mb-5">
              <v-toolbar-title><h4 class="font-weight-light">TRANSFER CATEGORY</h4>
              </v-toolbar-title>
            </v-toolbar>
            <v-layout row wrap class="align-center mx-2">
              <v-flex xs12 md12>
                <v-select
                  v-model="selection"
                  class="mx-2"
                  dense
                  outlined
                  label="Selection"
                  :items="['Personal','Tie-Up','SSS Maternity','Expense']"
                  :rules="rules.combobox_rule"
                ></v-select>
              </v-flex>
            </v-layout>
          </v-form>
          <v-col cols="12" v-show="alert_customize">
            <v-alert
              color="warning"
              text
              class="mb-0"
            >
              <div class="d-flex align-start">
                <v-icon color="warning">
                  {{ icons.mdiAlertOutline }}
                </v-icon>

                <div class="ms-3">
                  <p class="text-base font-weight-medium mb-1">
                    {{alert_message_customize}}
                  </p>
                </div>
              </div>
            </v-alert>
          </v-col>
        </v-card-text>
      </v-card>
      <v-btn color="primary" @click="transfer"> Save Changes</v-btn>
      <v-btn color="error" @click="Transfer = false"> Close</v-btn>
    </v-dialog>
    <v-dialog v-model="show" persistent width="50%" dark>
      <v-card color="primary">
        <v-card-text>
          {{ txt }}
          <v-progress-linear indeterminate color="white" class="mb-0"></v-progress-linear>
        </v-card-text>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
  import {
    mdiAccountOutline,
    mdiAccountCashOutline,
    mdiInformationOutline,
    mdiGoogleCirclesCommunities,
    mdiPrinter, mdiTransferRight, mdiFolderSwap,
  } from "@mdi/js";
  import moment from "moment";
  import {mapActions, mapGetters} from "vuex";
  import snackBarDialog from '@/components/dialogs/notifications_dialog/Snackbar'
  import category from "@/store/module/data/category";

  const initialState = () => {
    return {
      key: 0,
      selection: '',
      txt: '',
      Transfer: false,
      show: false,
      alert_message_customize: '',
      alert_customize: false,
      headers: [
        {text: 'ID', value: 'date_of_deposit', sortable: false},
        {text: 'Request ID', value: 'tin', sortable: false},
        {text: 'Category', value: 'tin', sortable: false},
        {text: 'Month Of', value: 'tin', sortable: false},
        {text: 'Branch', value: 'tin', sortable: false},
        {text: 'Voucher#', value: 'tin', sortable: false},
        {text: 'Name', value: 'tin', sortable: false},
        {text: 'Description', value: 'tin', sortable: false},
        {text: 'Amount', value: 'tin', sortable: false},
        {text: 'Balance', value: 'tin', sortable: false},
        {text: 'Action', value: 'tin', sortable: false},
      ],
      data_items: [],
      data_items2: [],
      data_items3: [],
      data_items4: [],
      data_items5: [],
      data_items6: [],
      search_items: [],
      tab: '',
      tabs: [
        {title: 'PERSONAL', icon: mdiGoogleCirclesCommunities},
        {title: 'SSS MATERNITY', icon: mdiAccountOutline},
        {title: 'CONSTRUCTION', icon: mdiAccountOutline},
        {title: 'FUND', icon: mdiAccountOutline},
        {title: 'BOND', icon: mdiAccountOutline},
        {title: 'EXPENSE', icon: mdiAccountOutline},
      ],
      tabs1: [
        {title: 'PERSONAL', icon: mdiGoogleCirclesCommunities},
      ],
      is_search: false,
      status: '',
      search: '',
      isLoading: false,
      total_amount: '0',
      total_balance: '0',
      id: 0,
    }
  }
  export default {
    components: {
      snackBarDialog,
    },
    setup() {
      return {
        icons: {
          mdiAccountOutline,
          mdiAccountCashOutline,
          mdiInformationOutline,
          mdiPrinter,
          mdiTransferRight,
          mdiFolderSwap,
        },
      }
    },
    data() {
      return initialState()
    },
    mounted() {
      if (!(this.position === 'ADMIN' || this.position === 'TREASURY' || this.position === 'ACCOUNTING' || this.position === 'CMD' || this.position === 'FINANCE'|| this.position === 'AR/AP')) {
        this.status = 'Unpaid'
        this.searching(this.name)
      }
    },
    computed: {
      ...mapGetters('form_rules', ['rules']),
      ...mapGetters('authentication', ['employee_id', 'address', 'contact', 'name', 'department', 'position']),
      ...mapGetters('system_data', ['snackbar', 'snackbar_color', 'snackbar_text', 'company_logo', 'damayan', 'chapel', 'factory', 'coop', 'printing']),
    },
    methods: {
      ...mapActions('system_data', ['change_snackbar']),
      ...mapActions('request_data', ['search_of_cash_advance', 'change_ca_to_bod_charge', 'change_ca_particulars', 'list_of_cash_advance']),
      formatPrice(value) {
        let val = (value / 1).toFixed(2).replace(',', '.')
        return val.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',')
      },
      balance_calculation(item) {

        var total = item.amount
        var payment = 0
        item.ca_payment.forEach(function (item) {
          payment += parseFloat(item.amount)
        });
        var balance = (total - payment)

        return this.formatPrice(balance)
      },
      balance_calculation_expense(item) {
        var total = item.amount
        var payment = 0
        item.ca_payment.forEach(function (item) {
          payment += parseFloat(item.amount)
        });
        item.liquidation.forEach(function (item) {
          payment += parseFloat(item.amount)
        });
        var balance = (total - payment)

        return this.formatPrice(balance)
      },
      searching(value) {
        this.show = true
        this.search = value
        this.data_items = []
        this.data_items2 = []
        this.data_items3 = []
        this.data_items4 = []
        this.data_items5 = []
        this.data_items6 = []
        this.total_amount = '0'
        this.total_balance = '0'
        this.txt = 'SEARCHING UNPAID CASH ADVANCE ' + value
        this.search_of_cash_advance({
          search_word: value,
          status: this.status
        })
          .then(response => {
            this.total_amount = response.data[0].total_amount
            this.total_balance = response.data[0].total_balance
            this.data_items = response.data[0].personal
            this.data_items2 = response.data[0].sss_maternity
            this.data_items3 = response.data[0].construction
            this.data_items4 = response.data[0].fund
            this.data_items5 = response.data[0].bond
            this.data_items6 = response.data[0].expense
            this.isLoading = false
            this.txt = ''
            this.show = false
          })
      },
      selected_status(value) {
        this.show = true
        this.txt = 'SEARCHING CASH ADVANCE UNPAID'
        this.data_items = []
        this.data_items2 = []
        this.data_items3 = []
        this.data_items4 = []
        this.data_items5 = []
        this.data_items6 = []
        this.total_amount = '0'
        this.total_balance = '0'
        if (!this.is_search) {
          this.list_of_cash_advance({
            status: value
          })
            .then(response => {
              this.total_amount = response.data[0].total_amount
              this.total_balance = response.data[0].total_balance
              this.data_items = response.data[0].personal
              this.data_items2 = response.data[0].sss_maternity
              this.data_items3 = response.data[0].construction
              this.data_items4 = response.data[0].fund
              this.data_items5 = response.data[0].bond
              this.data_items6 = response.data[0].expense
              this.isLoading = false
              this.txt = ''
              this.show = false
            })
        } else {
          this.searching(this.search)
        }
      },
      bod_charge(item) {
        if (confirm("PROCEED?")) {
          this.change_ca_to_bod_charge({
            id: item.id
          })
            .then(() => {
              this.selected_status(this.status)
            })
        }
      },
      transfer_data(item) {
        if (confirm("PROCEED?")) {
          this.id = item.id
          this.Transfer = true
          this.key++
        }
      },
      transfer() {
        if (confirm("PROCEED?")) {
          this.change_ca_particulars({
            id: this.id,
            selection: this.selection
          })
            .then(() => {
              this.Transfer = false
              this.selected_status(this.status)
            })
        }
      },
      print_soa(value) {
        if (confirm("PROCEED?")) {
          var imgData = this.company_logo
          var payments_array = []
          var widths = [100, 60, 80, 100, 60]
          var payment = 0
          payments_array.push([
            {text: 'Month Of', alignment: 'left', style: 'label',},
            {text: 'Duration', alignment: 'left', style: 'label'},
            {text: 'Date', alignment: 'left', style: 'label'},
            {text: 'Particulars', alignment: 'left', style: 'label'},
            {text: 'Amount', alignment: 'left', style: 'label'},
          ])
          if (value.ca_payment.length > 0) {
            value.ca_payment.forEach(function (item) {
              if (item.deposit_slip != null) {
                payment += parseFloat(item.amount)
                payments_array.push([
                  {text: item.transaction_month.month_of, alignment: 'left', style: 'tableExample'},
                  {text: item.duration, alignment: 'left', style: 'tableExample'},
                  {text: item.deposit_slip.date, alignment: 'left', style: 'tableExample'},
                  {text: item.particulars, alignment: 'left', style: 'tableExample'},
                  {
                    text: (item.amount / 1)
                      .toFixed(2)
                      .replace(',', '.')
                      .toString()
                      .replace(/\B(?=(\d{3})+(?!\d))/g, ','),
                    alignment: 'left', style: 'tableExample'
                  },
                ])
              }
            })
          }
          //call pdfmake
          var pdfMake = require('pdfmake/build/pdfmake.js')
          if (pdfMake.vfs == undefined) {
            var pdfFonts = require('pdfmake/build/vfs_fonts.js')
            pdfMake.vfs = pdfFonts.pdfMake.vfs
          }
          var docDefinition = {
            pageSize: {
              width: 612,
              height: 936,
            },
            pageOrientation: 'portrait',
            content: [
              {
                columns: [
                  {image: imgData, width: 54, height: 54, style: 'logo'},
                  {
                    stack: [
                      'GOODLIFE GROUP OF COMPANIES',
                      {text: this.address, style: 'subheader'},
                      {
                        text: this.contact,
                        style: 'subheader',
                      },
                      {
                        text: 'Printed as of: ' + moment().format('MMMM D, YYYY'),
                        style: 'printed_label',
                      },
                    ],
                    style: 'header',
                  },
                ],
              },
              '_______________________________________________________________________________________________',
              {
                stack: [
                  {
                    columns: [
                      {
                        text: 'CASH ADVANCE - STATEMENT OF ACCOUNT (SOA)',
                        style: {
                          fontSize: 16,
                          alignment: 'center',
                          bold: true,
                          color: 'blue',
                        },
                      },
                    ],
                  },
                ],
              },
              {
                columns: [{text: ' '}],
              },
              {
                columns: [{text: ' '}],
              },
              {
                stack: [
                  {
                    columns: [
                      {
                        style: 'main_info',
                        text: [
                          'NAME: ',
                          {
                            text: value.payment_for,
                            style: 'main_data',
                          },
                        ],
                      },
                      {text: ''},
                      {
                        style: 'main_info',
                        text: [
                          'Total Amount: ',
                          {
                            text: this.formatPrice(value.amount),
                            style: 'main_data', color: 'blue',
                          },
                        ],
                      },
                    ],
                  },
                  {
                    columns: [
                      {
                        style: 'main_info',
                        text: [
                          'Branch: ',
                          {
                            text: value.branch != null ? value.branch.branch_code : '',
                            style: 'main_data',
                          },
                        ],
                      },
                      {text: ''},
                      {
                        style: 'main_info',
                        text: [
                          'Total Payed: ',
                          {
                            text: this.formatPrice(payment),
                            style: 'main_data', color: 'green',
                          },
                        ],
                      },
                    ],
                  },
                  {
                    columns: [
                      {text: ''},
                      {text: ''},
                      {
                        style: 'main_info',
                        text: [
                          'Balance: ',
                          {
                            text: this.formatPrice(parseFloat(value.amount) - parseFloat(payment)),
                            style: 'main_data', color: 'red',
                          },
                        ],
                      },
                    ],
                  },
                  {
                    columns: [
                      {
                        style: 'main_info',
                        text: [
                          'Purpose: ',
                          {
                            text: value.description,
                            style: 'main_data',
                          },
                        ],
                      },
                    ],
                  },
                ],
              },
              {
                columns: [{text: ' '}],
              },
              'PAYMENT',
              {
                table: {
                  widths: widths,
                  body: payments_array,
                  style: {fontSize: 1},
                },
              },
              {
                stack: [
                  {
                    columns: [{text: ' '}],
                  },
                  {
                    columns: [{text: ' '}],
                  },
                  {
                    columns: [
                      {
                        text: 'PROCESSED BY',
                        style: 'main_data_2',
                      },
                      {
                        text: 'DEPARTMENT RECEIVER',
                        style: 'main_data_2',
                      },
                      {
                        text: 'RECEIVED BY',
                        style: 'main_data_2',
                      },
                    ],
                  },
                  {
                    columns: [
                      {
                        text: this.name,
                        style: 'main_data_2',
                      },
                      {
                        text: ' ',
                      },
                      {
                        text: '',
                      },
                    ],
                  },
                  {
                    columns: [
                      {
                        text: '_____________________',
                      },
                      {
                        text: '_____________________',
                      },
                      {
                        text: '_____________________',
                      },
                    ],
                  },
                  {
                    columns: [
                      {
                        text: this.department,
                        style: 'main_data_2',
                      },
                      {
                        text: 'Signature Over Printed Name',
                        style: 'main_data_2',
                      },
                      {
                        text: 'Signature Over Printed Name',
                        style: 'main_data_2',
                      },
                    ],
                  },
                  {
                    columns: [
                      {
                        text: 'VERIFIED BY',
                        style: 'main_data_2',
                      },
                      {
                        text: '',
                      },
                      {
                        text: '',
                      },
                    ],
                  },
                  {
                    columns: [
                      {
                        text: '_________________________________________',
                      },
                      {
                        text: ' ',
                      },
                      {
                        text: ' ',
                      },
                    ],
                  },
                  {
                    columns: [
                      {
                        text: 'BOARD OF DIRECTOR',
                        style: 'main_data_2',
                      },
                      {
                        text: '',
                      },
                      {
                        text: '',
                      },
                    ],
                  },
                ],
              },
              {
                columns: [
                  {image: this.damayan, width: 54, height: 40, style: 'logo'},
                  {image: this.chapel, width: 54, height: 40, style: 'logo'},
                  {image: this.factory, width: 54, height: 40, style: 'logo'},
                  {image: this.coop, width: 54, height: 40, style: 'logo'},
                  {image: this.printing, width: 54, height: 40, style: 'logo'},
                ],
              },
            ],
            footer: {
              // columns: [
              //     {
              //         text: 'Generated with Goodlife Savings & Credit Cooperative System',
              //         alignment: 'center',
              //         style: 'tableExample'
              //     }
              // ]
            },
            styles: {
              tableExample: {
                fontSize: 9,
              },
              tableExample2: {
                fontSize: 7,
              },
              header: {
                fontSize: 21,
                bold: true,
                alignment: 'left',
                margin: [6, 6, 0, 5], //[left, top, right, bottom]
              },
              subheader: {
                fontSize: 11,
              },
              logo: {
                alignment: 'center',
                margin: [0, 0, 0, 0], //[left, top, right, bottom]
              },
              printed_label: {
                alignment: 'right',
                fontSize: 9,
                margin: [0, 0, 0, 0], //[left, top, right, bottom]
              },
              main_data: {
                margin: [0, 2, 0, 2],
                fontSize: 11,
                bold: true,
                alignment: 'left',
              },
              main_data_2: {
                margin: [0, 2, 0, 2],
                fontSize: 8,
                bold: true,
                alignment: 'left',
              },
              main_info: {
                margin: [0, 2, 0, 2],
                fontSize: 10,
                alignment: 'left',
              },
            },
          }
          pdfMake.createPdf(docDefinition).open()
          this.saving = false
        }
      },
    }
  }
</script>
